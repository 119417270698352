<template>
  <v-row
    justify="space-between"
    class="head-search ma-0">
    <v-col
      cols="4"
      class="d-flex align-center date-picker-col">
      <date-range-picker
        v-model="saleDates"
        @change="dateChange($event)" />
    </v-col>
    <v-col
      cols="2"
      class="d-flex align-center date-picker-col">
      <v-select
        v-model="warehouseSelected"
        :items="warehouses"
        :menu-props="{ offsetY: true }"
        item-value="id"
        item-text="name"
        label="Warehouses"
        class="select-warehouse"
        return-object
        auto-select-first
        outlined
        hide-details
        dense />
    </v-col>
    <v-col
      cols="2"
      class="d-flex align-center date-picker-col">
      <v-select
        v-model="brandSelected"
        label="Brand"
        :items="allBrand"
        outlined
        dense
        hide-details />
    </v-col>
    <v-spacer />
    <v-col
      cols="4"
      class="d-flex align-center date-picker-col">
      <v-spacer />
      <v-btn
        class="mx-2"
        color="primary"
        :disabled="findBtnDisabled"
        @click="find()">
        find
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker.vue'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'

const ProductAttributeService = new ProductAttributeProvider()
export default {
  components: {
    DateRangePicker
  },
  props: {
    warehouses: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      saleDates: [this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
      warehouseSelected: null,
      allBrand: [],
      brandSelected: 'all'
    }
  },
  computed: {
    findBtnDisabled () {
      return this.warehouseSelected === null
    }
  },
  watch: {
    warehouseSelected: {
      handler (value) {
        this.$emit('change-warehouse', value)
      },
      deep: true
    },
    saleDates: {
      handler (value) {
        this.$emit('change-date', value)
      },
      deep: true
    }
  },
  async mounted () {
    await this.getBrand()
  },
  methods: {
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })

        this.allBrand = [
        {
          text: 'All',
          value: 'all'
        },
          ...data.results.map((brand) => ({
            text: brand.name,
            value: brand.name
          }))
        ]
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    dateChange (date) {
      this.$emit('change-date', date)
    },
    warehouseChange (warehouse) {
      this.$emit('change-warehouse', warehouse)
    },
    find () {
      const payload = {
        startDate: this.$dayjs(this.saleDates[0]).utc().toISOString(),
        endDate: this.$dayjs(this.saleDates[1]).add(1, 'day').utc().toISOString(),
        branchId: this.warehouseSelected.id,
        brand: this.brandSelected
      }

      this.$emit('find-clicked', payload)
    }
  }
}
</script>
