<template>
  <v-container
    class="products-list-page"
    fluid>
    <sale-report-header
      :warehouses="reMapWarehouses()"
      @change-warehouse="changeWarehouse($event)"
      @change-date="changeDate($event)"
      @find-clicked="find($event)" />
    <v-row class="products-row">
      <v-col cols="12">
        <sale-report-table
          :headers="headers"
          :items="bills"
          is-national />
      </v-col>
    </v-row>
    <v-row class="products-row">
      <v-col
        cols="12"
        class="d-flex flex-wrap justify-center">
        <v-card
          outlined
          class="stats-card">
          <v-card-text class="text-center">
            <div>Count Bill</div>
            <p class="title text--primary">
              {{ infos.countBill }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="stats-card">
          <v-card-text class="text-center">
            <div>Total Full Price</div>
            <p class="title text--primary">
              ${{ infos.totalFullPrice | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="stats-card">
          <v-card-text class="text-center">
            <div>Total Unit Discount</div>
            <p class="title text--primary">
              ${{ infos.totalUnitDiscount | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="stats-card">
          <v-card-text class="text-center">
            <div>Total Bill Discount</div>
            <p class="title text--primary">
              ${{ infos.totalBillDiscount | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="stats-card">
          <v-card-text class="text-center">
            <div>Total Bill Net International</div>
            <p class="title text--primary">
              ${{ infos.totalBillNet | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="stats-card">
          <v-card-text class="text-center">
            <div>Total Bill Net THB</div>
            <p class="title text--primary">
              ฿{{ +infos.totalBillNet * multipleCurrency | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-btn
          color="success"
          min-width="15%"
          class="mx-2 mt-9"
          :disabled="!exportable"
          @click="exportBills(rawBills)">
          <v-icon class="mr-1">
            mdi-export
          </v-icon>
          Export CSV
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MemberService from '@/resources/MemberProvider'
import { ExportToCsv } from 'export-to-csv'
import CurrencyProvider from '@/resources/CurrencyProvider'
import SaleReportHeader from '../components/SaleReportHeaderSg.vue'
import SaleReportTable from '../components/SaleReportTable.vue'

const CurrencyService = new CurrencyProvider()
const MemberProvider = new MemberService()
export default {
  components: {
    SaleReportHeader,
    SaleReportTable
  },
  data () {
    return {
      headers: [
        { text: 'Bill ID', align: 'center', value: 'billId', sortable: false, width: '15%' },
        { text: 'Create Time', align: 'center', value: 'createTime', sortable: false, width: '15%' },
        { text: 'Item Amount', align: 'center', value: 'itemAmount', sortable: false },
        { text: 'Total (USD)', align: 'center', value: 'subTotal', sortable: false },
        { text: 'Total (THB)', align: 'center', value: 'thbTotal', sortable: false },
        { text: 'Unit Discount', align: 'center', value: 'unitDiscount', sortable: false },
        { text: 'Promotion Code', align: 'center', value: 'promotionCode', sortable: false },
        { text: 'Shipping Fee', align: 'center', value: 'shipCost', sortable: false },
        { text: 'Bill Net (USD)', align: 'center', value: 'sgNet', sortable: false },
        { text: 'Bill Net (THB)', align: 'center', value: 'thbNet', sortable: false },
        { text: 'Branch', align: 'center', value: 'branch.name', sortable: false, width: '15%' },
        { text: 'Channel', align: 'center', value: 'channel', sortable: false },
        { text: 'Cashier', align: 'center', value: 'cashier', sortable: false, width: '15%' },
        { text: 'Customer Tel.', align: 'center', value: 'customerTel', sortable: false }
      ],
      rawBills: [],
      bills: [],
      infos: {
        countBill: 0,
        totalFullPrice: 0,
        totalUnitDiscount: 0,
        totalBillDiscount: 0,
        totalBillNet: 0
      },
      warehouse: null,
      currencyMappingSgd: {},
      currencyMappingThb: {},
      dates: [null, null],
      currentWarehouse: null,
      multipleCurrency: 33.19
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    exportable () {
      return this.bills.length > 0
    }
  },
  created () {
    this.getCurrencies()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    reMapWarehouses () {
      return this.mapWarehouse.map((w) => {
        if (w.id === 0) {
          return {
            ...w,
            name: 'Online main'
          }
        }

        return w
      })
    },
    async getCurrencies () {
      try {
        this.loading = true
        const { data: { results } } = await CurrencyService.getCurrencies({ page: 1, limit: 99999 })

        for (const re of results) {
          const usd = re.foriegnCurrencies.find((fc) => fc.isoCode === 'usd')

          if (usd) {
            this.currencyMappingSgd[re.thb] = {
              thb: re.thb,
              usd: usd.value
            }
            this.currencyMappingThb[usd.value] = {
              thb: re.thb,
              usd: usd.value
            }
          }
        }
      } catch (error) {
        console.error(`[GET-CURRENCIES-ERROR]: ${error.message}`)
      } finally {
        this.loading = false
      }
    },
    changeDate (date) {
      this.dates = date
    },
    changeWarehouse (warehouse) {
      this.warehouse = warehouse
    },
    find (payload) {
      this.fetchBills(payload)
    },
    async fetchBills (payload) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING REPORT...'
        })

        const { data } = await MemberProvider.getMemmberOrderHistoryListSG(payload)
        this.rawBills = data
        this.bills = this.mapData(data)
        this.infos = this.initInfos(this.bills)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    mapData (data) {
      const mapped = data.map((each) => {
        let subTotal = 0
        let unitDiscount = 0
        let thbTotal = 0
        for (const item of each.orderItems) {
          const unitPrice = item.fullPrice / item.amount
          const sgUnitPrice = this.currencyMappingSgd[unitPrice]?.usd || unitPrice
          const sgFullPrice = sgUnitPrice * item.amount
          const sgSalePrice = item.price / item.amount
          const thbSalePrice = this.currencyMappingThb[sgSalePrice]?.thb || sgSalePrice
          const thbPrice = thbSalePrice * item.amount
          thbTotal += thbPrice
          unitDiscount += sgFullPrice - item.price
          subTotal += sgFullPrice
        }

        return {
          billId: each.order.id,
          createTime: this.$dayjs(each.order.createTime).add(7, 'hour').format('DD/MM/YYYY | HH:mm'),
          itemAmount: each.orderItems.reduce((total, acc) => total + acc.amount, 0),
          subTotal,
          thbTotal,
          shipCost: each.order?.shipCost || each.order?.net - unitDiscount - subTotal || 0,
          unitDiscount,
          billDiscount: each.order.discount,
          promotionCode: each.promotionCode?.code || '',
          sgNet: each.order.net,
          total: each.order.net,
          thbNet: each.order.net * this.multipleCurrency,
          branch: each.branch,
          channel: each.channel,
          cashier: each.cashier?.name || '',
          customerTel: each.member?.tel || '',
          code: each.promotionCode?.code || '',
          payment: each.payment?.name || ''
        }
      })

      return mapped
    },
    initInfos (bills) {
      const mapped = {
        countBill: bills.length,
        totalFullPrice: bills.reduce((total, bill) => total + bill.subTotal, 0),
        totalUnitDiscount: bills.reduce((total, bill) => total + bill.unitDiscount, 0),
        totalBillDiscount: bills.reduce((total, bill) => total + bill.billDiscount, 0),
        totalBillNet: bills.reduce((total, bill) => total + bill.total, 0)
      }

      return mapped
    },
    exportBills (bills) {
      const skus = []
      for (const bill of bills) {
        const subTotal = bill.orderItems.reduce((sum, it) => it.price + sum, 0)
        bill.orderItems.forEach((item) => {
          skus.push({
            billId: bill.order.id,
            skuId: item.skuId,
            cashier: bill.cashier?.name || '',
            tel: bill.member?.tel || '',
            productName: item?.name || '',
            time: this.$dayjs(bill.order.createTime).add(7, 'hour').format('DD/MM/YYYY | HH:mm'),
            options: `${item.color}:${item.size}`,
            pricePerUnit: item.fullPrice,
            amount: item.amount,
            shipCost: bill.order?.shipCost || (bill.order?.net + bill.order?.discount) - subTotal || 0,
            promotionCode: item.promotionCode?.code || '',
            price: item.fullPrice,
            unitDiscount: item.fullPrice - item.price,
            netPrice: item.price,
            billDiscount: bill.order.discount,
            netBillPrice: bill.order.net,
            code: bill.promotionCode?.code || '',
            payment: bill.payment?.name || '',
            channel: bill.channel,
            store: bill.store?.name || '',
            branch: bill.branch?.name || '',
            note: bill.order.note
          })
        })
      }

      const blank = {
        billId: '',
        skuId: '',
        cashier: '',
        tel: '',
        productName: '',
        time: '',
        options: '',
        pricePerUnit: '',
        amount: '',
        promotionCode: '',
        price: '',
        unitDiscount: '',
        netPrice: '',
        billDiscount: '',
        netBillPrice: '',
        shipCost: '',
        code: '',
        payment: '',
        channel: '',
        store: '',
        branch: '',
        note: ''
      }

      const infos = [
        { ...blank },
        { ...blank, billDiscount: 'Count Bill', netBillPrice: this.infos.countBill },
        { ...blank, billDiscount: 'Total Full Price', netBillPrice: this.infos.totalFullPrice },
        { ...blank, billDiscount: 'Total Unit Discount', netBillPrice: this.infos.totalUnitDiscount },
        { ...blank, billDiscount: 'Total Bill Discount', netBillPrice: this.infos.totalBillDiscount },
        { ...blank, billDiscount: 'total Bill Net', netBillPrice: this.infos.totalBillNet }
      ]
      const preparedExport = [...skus, ...infos]
      const options = {
        filename: `Sold_Report_${this.warehouse?.name}`,
        showLabels: true,
        useKeysAsHeaders: true
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(preparedExport)
    }
  }
}
</script>

<style>
.stats-card {
  min-width: 15%;
  margin: 8px;
}
</style>
